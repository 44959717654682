<!-- <template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
         <card>
          <template v-slot:headerAction>
            <router-link to="allowance" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tim.allowance') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button variant="primary" @click="pdfExport" class="">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
          </template> -->
<template>
<div class="inner-section">
        <!-- <card>
      </card> -->
    <body-card>
        <template v-slot:headerAction>
          <router-link to="allowance" class="btn-add mr-2"><i class="ri-arrow-left-line"></i> {{ $t('elearning_tim.allowance') }} {{ $t('globalTrans.list') }}</router-link>
          <b-button variant="primary" size="sm" @click="pdfExport" class="mr-2">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button>
        </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col v-if="honorariumSheet.org_id">
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="honorariumSheet.org_id ? honorariumSheet.org_id : 10">
                          {{ $t('elearning_tim.allowance') + ' ' + $t('globalTrans.details') }}
                      </list-report-head>
                  </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple bordered>
                    <b-tr>
                      <b-th style="width: 18%" class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</b-th>
                      <b-td style="width: 32%" class="text-left">{{ honorariumSheet.circular_memo_no }}</b-td>
                      <b-th style="width: 18%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</b-th>
                      <b-td style="width: 32%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? honorariumSheet.org_bn : honorariumSheet.org }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-left" >{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-td class="text-left">{{ $i18n.locale === 'bn' ? honorariumSheet.fiscal_year_bn : honorariumSheet.fiscal_year }}</b-td>
                      <b-th class="text-left">{{ $t('elearning_config.training_type') }}</b-th>
                      <b-td>{{ $i18n.locale === 'bn' ? honorariumSheet.training_type_bn : honorariumSheet.training_type }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th class="text-left">{{ $t('elearning_config.training_title') }}</b-th>
                      <b-td>{{ $i18n.locale === 'bn' ? honorariumSheet.training_title_bn : honorariumSheet.training_title }}</b-td>
                      <b-th class="text-left">{{ $t('elearning_config.batch') }}</b-th>
                      <b-td>{{ $i18n.locale === 'bn' ? honorariumSheet.batch_bn : honorariumSheet.batch_en }}</b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
              <body-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.allowance')}} {{$t('globalTrans.list')}}</h4>
                      </template>
                      <template v-slot:body>
                        <row>
                          <div class="col-md-12">
              <b-overlay :show="load">
                <b-row id="form">
                  <div class="col-md-12">
                        <b-table-simple class="table" style="width:100%" bordered>
                          <b-tr>
                            <b-th class="text-center" style="width:5%">{{$t('globalTrans.sl_no')}}</b-th>
                            <b-th style="width:12%">{{$t('globalTrans.name')}}</b-th>
                            <b-th style="width:12%">{{$t('globalTrans.designation')}}</b-th>
                            <b-th style="width:7%" class="text-right">{{$t('elearning_tim.number_of_class')}}</b-th>
                            <b-th style="width:8%" class="text-right">{{$t('elearning_tim.allowance')}}</b-th>
                            <b-th style="width:7%" class="text-right">{{$t('globalTrans.total_amount')}}</b-th>
                            <b-th style="width:7%" class="text-right">{{$t('globalTrans.income_tax')}} (%)</b-th>
                            <b-th style="width:7%" class="text-right">{{$t('globalTrans.income_tax_amount')}}</b-th>
                            <b-th style="width:7%" class="text-right">{{$t('globalTrans.other_detuction')}}</b-th>
                            <b-th style="width:10%" class="text-right">{{$t('globalTrans.net_amount')}}</b-th>
                            <b-th style="width:19%">{{$t('globalTrans.signature_date_&_stamp')}}</b-th>
                          </b-tr>
                          <b-tr v-for="(data, index) in trainees" :key="index">
                            <b-td class="text-center" >
                              {{ $n(index + 1) }}
                            </b-td>
                            <b-td>
                              {{ $i18n.locale === 'bn' ? data.personal.name_bn : data.personal.name }}
                            </b-td>
                            <b-td>
                              {{ ($i18n.locale==='bn') ? data.personal.professional_info.designation_bn : data.personal.professional_info.designation_en }}
                            </b-td>
                            <b-td class="text-right">
                              {{ $n(data.total_days) }}
                            </b-td>
                            <b-td class="text-right">
                              {{ $n(data.honour_amount) }}
                            </b-td>
                            <b-td class="text-right">
                              {{$n(data.total_days * data.honour_amount)}}
                            </b-td>
                            <b-td class="text-right">
                              {{$n(data.vat_tax)}}
                            </b-td>
                            <b-td class="text-right">
                              {{$n(data.vat_tax_amount)}}
                            </b-td>
                            <b-td class="text-right">
                              {{ $n(data.other) }}
                            </b-td>
                            <b-td class="text-right">
                              {{ $n(data.payment_amount) }}
                            </b-td>
                            <b-td></b-td>
                          </b-tr>
                        </b-table-simple>
                  </div>
                </b-row>
              </b-overlay>
                          </div>
                        </row>
                      </template>
                    </body-card>
            </b-overlay>
          </template>
        </body-card>
      </div>
</template>
<script>

import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { billPaymentHonorarumDetails } from '../../api/routes'

export default {
    components: {
        ListReportHead
    },
    created () {
        if (this.$route.query.circilarMemoNo) {
            this.getCircularMemoNo(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.honorariumSheet.training_title_id)
    },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      items: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
        } else {
            this.honorariumSheet = result.data[0]
            this.getCustomDataZeroList(result.data[0])
            const trainees = result.data.filter(trainee => trainee.payment_type === 'Trainee')
            this.trainees = this.getCustomDataList(trainees)
            this.materials = result.matherial
        }
        this.load = false
      }
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.allowance') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.trainees)
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.personal.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          // const desigObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          // const desigData = {}
          // if (typeof desigObj !== 'undefined') {
          //   desigData.designation_name = desigObj.text_en
          //   desigData.designation_name_bn = desigObj.text_bn
          // } else {
          //   desigData.designation_name = ''
          //   desigData.designation_name_bn = ''
          // }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, trainingTypeData, trainingCategoryData, customItem)
        })
        return listData
      },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(item1 => item1.value === parseInt(item.batch_id))
          if (typeof batchObj !== 'undefined') {
            this.honorariumSheet.batch_en = batchObj.text_en
            this.honorariumSheet.batch_bn = batchObj.text_bn
          } else {
            this.honorariumSheet.batch_en = ''
            this.honorariumSheet.batch_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
